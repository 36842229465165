<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button *ngIf="enabledFilters" (click)="clearFilters()" class="clear-button">
        {{ "Clear" | translate }}
      </ion-button>
    </ion-buttons>
    <ion-title>{{ "Filters" | translate }} : {{ "Tasks" | translate | titlecase }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list class="filters-list" lines="full">
    <ion-item-sliding *ngFor="let filter of filters" (ionSwipe)="clearFilter(item, filter)" #item>
      <ion-item>
        <ion-label>{{ filter.label }}</ion-label>
        <ion-select
          multiple="true"
          [(ngModel)]="filter.selectedValues"
          (ngModelChange)="onFilterSelected(filter)"
          [disabled]="filter.availableValues.length === 0"
          [placeholder]="defaultValueLabel">
          <ion-select-option *ngFor="let value of filter.availableValues; index as i" [value]="value">
            {{ filter.availableValuesLabels[i] }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item-options>
        <ion-select-option>
          <button ion-button expandable color="danger" (click)="clearFilter(item, filter)">
            {{ "Clear" | translate }}
          </button>
        </ion-select-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-list>
</ion-content>
