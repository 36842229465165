<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="close()">
        <i class="fa-solid fa-circle-chevron-left back-icon"></i>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ getPageTitle() }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="!task">
    <ion-card class="no-border">
      <ion-card-content>
        {{ "Task not found" | translate }}
      </ion-card-content>
    </ion-card>
  </div>

  <ion-list *ngIf="task">
    <input-item [legend]="'Label' | translate" labelClass="color-reference-data">
      <ion-textarea
        autosize
        type="text"
        placeholder="-"
        [(ngModel)]="task.label"
        (ionFocus)="setPreviousValue($event)"
        (ionBlur)="saveFieldIfChanged('label')"></ion-textarea>
    </input-item>

    <input-item [legend]="'Owner' | translate" labelClass="color-reference-data">
      <ion-select [(ngModel)]="task.assigned" (ngModelChange)="saveChanges()">
        <ion-select-option *ngFor="let user of users" [value]="user.get_user_id">
          {{ user | username }}
        </ion-select-option>
      </ion-select>
    </input-item>

    <input-item [legend]="'Deadline' | translate" labelClass="color-reference-data">
      <ion-popover trigger="deadline" size="auto">
        <ng-template>
          <ion-datetime
            presentation="month-year"
            min="{{ minDate }}"
            max="{{ maxDate }}"
            [(ngModel)]="task.deadline"
            (ngModelChange)="saveChanges()"></ion-datetime>
        </ng-template>
      </ion-popover>

      <div id="deadline">
        <ion-text>
          {{ task.deadline | date: "MMMM y" | titlecase }}
          <span *ngIf="!task.deadline">-</span>
        </ion-text>
      </div>
    </input-item>

    <input-item [legend]="'Status' | translate" labelClass="color-reference-data">
      <ion-select [(ngModel)]="task.state" (ngModelChange)="saveChanges()">
        <ion-select-option *ngFor="let taskState of taskStates" [value]="taskState.id">
          {{ taskState.name }}
        </ion-select-option>
      </ion-select>
    </input-item>

    <legend-item
      *ngIf="relatedAsset"
      [legend]="'Related' | translate"
      (click)="loadAssetDetails()"
      labelClass="color-reference-data">
      {{ relatedAsset.label }}
    </legend-item>

    <legend-item
      *ngIf="relatedInvestment"
      [legend]="'Related' | translate"
      (click)="loadInvestmentDetails()"
      labelClass="color-reference-data">
      {{ relatedInvestment.label }}
    </legend-item>

    <input-item [legend]="'Perimeter' | translate" labelClass="color-reference-data">
      <ion-select [(ngModel)]="task.perimeter" (ngModelChange)="saveChanges()">
        <ion-select-option *ngFor="let perimeter of currentMultiPerimeter?.sub_perimeters" [value]="perimeter.id">
          {{ perimeter.name }}
        </ion-select-option>
      </ion-select>
    </input-item>

    <input-item [legend]="'Remarks' | translate" labelClass="color-reference-data" lines="none">
      <ion-textarea
        autosize
        placeholder="-"
        [(ngModel)]="task.description"
        (ionFocus)="setPreviousValue($event)"
        (ionBlur)="saveFieldIfChanged('description')"></ion-textarea>
    </input-item>
  </ion-list>
</ion-content>

<ion-footer>
  <app-footer-next-button
    *ngIf="task && isNewTask()"
    nextButton
    (next)="save($event)"
    [label]="getSaveButtonLabel()"
    [enabled]="canSaveTask()"
    color="reference-data"
    textColor="light"
    [labelChangedEvent]="nextLabelChanged"></app-footer-next-button>

  <ion-toolbar *ngIf="showRiskLink()" mode="ios" color="risk-major">
    <div class="buttons-toolbar">
      <ion-buttons mode="ios" class="bar-buttons">
        <ion-button (click)="openRisk()">
          <i class="fa-light fa-diamond-exclamation icon-padding"></i>
          (1)
        </ion-button>
      </ion-buttons>
    </div>
  </ion-toolbar>
</ion-footer>
