<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <app-back-button *ngIf="!modalMode"></app-back-button>
      <ion-button *ngIf="modalMode" (click)="backButtonClicked()">
        <i class="fa-solid fa-circle-chevron-left fa-back"></i>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="!monoPerimeterId">{{ "Perimeters" | translate }}</ion-title>
    <ion-title *ngIf="monoPerimeterId">{{ "Children-perimeters" | translate }}</ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar
      [(ngModel)]="searchText"
      (ionInput)="searchPerimeters()"
      placeholder="{{ 'Filter perimeters' | translate }}"
      (ionClear)="searchPerimeters()"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="synthesisReady$ | async; else loading">
    <app-mono-perimeter-list-segment
      #monoPerimeterList
      [mainPerimeter]="mainPerimeter"
      [limitedList]="limitedList"
      [searchText]="searchText"
      (monoPerimeterClicked)="monoPerimeterClicked($event)"
      (pictureClickedEv)="pictureClicked($event)"
      (deleteMonoPerimeterEv)="deletePerimeter($event)"
      [selectedMonoPerimeterId]="selectedMonoPerimeterId"
      [childrenPerimetersMode]="!!monoPerimeterId"
      [showSwipeButtons]="!modalMode"
      [newPerimeter]="newPerimeter"></app-mono-perimeter-list-segment>
  </ng-container>
  <ng-template #loading>
    <ion-list>
      <ion-list-header>
        <ion-skeleton-text [animated]="true" style="width: 80px"></ion-skeleton-text>
      </ion-list-header>
      <ion-item>
        <ion-thumbnail slot="start">
          <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        </ion-thumbnail>
        <ion-label>
          <h3>
            <ion-skeleton-text [animated]="true" style="width: 80%"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text [animated]="true" style="width: 60%"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text [animated]="true" style="width: 30%"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
    </ion-list>
  </ng-template>

  <div class="medium-space"></div>

  <ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="showAddButton()">
    <ion-fab-button color="mono-perimeter" (click)="addPerimeter()">
      <ion-text color="light">
        <i class="fal fa-2x fa-plus"></i>
      </ion-text>
    </ion-fab-button>
  </ion-fab>
</ion-content>
