<ion-content #content>
  <ion-list lines="none">
    <ng-container *ngFor="let riskCategory of riskCategories$ | async; index as categoryIndex">
      <ion-item-divider
        color="light-gray"
        (click)="visibilityToggle(riskCategory.id)"
        [ngClass]="isVisible(riskCategory.id) ? 'unfolded-section' : 'folded-section'">
        <ion-label class="ion-text-center" style="width: 90%">
          <span *ngIf="isVisible(riskCategory.id)">
            (
            <i class="fal fa-minus"></i>
            )
          </span>
          <span *ngIf="!isVisible(riskCategory.id)">
            (
            <i class="fal fa-plus"></i>
            )
          </span>
          {{ riskCategory.name }}
          <span [hidden]="!getRisksForCategory(riskCategory)" class="danger-class">
            ({{ getRisksForCategory(riskCategory) }})
          </span>
        </ion-label>
      </ion-item-divider>
      <div *ngFor="let riskSubCategory of riskCategory.sub_categories" id="sub-category{{ riskSubCategory.id }}">
        <div lines="none" [hidden]="!isVisible(riskCategory.id)">
          <app-risk-item
            [riskSubCategory]="riskSubCategory"
            [risk]="getPerimeterRisk(riskSubCategory.id)"
            [perimeter]="perimeter"
            [site]="site"
            (addTaskEvent)="addTask($event)"
            (deleteRiskEvent)="deleteRisk($event)"
            (changeCriticalityEvent)="showCriticalitySheet($event)"
            [budget]="getSubCategoryBudget(riskSubCategory.id)"></app-risk-item>
          <hr />
        </div>
      </div>
    </ng-container>
  </ion-list>
</ion-content>
