import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AlertController, ModalController, NavController, NavParams } from "@ionic/angular";
import { InvestmentsService } from "../../../services/investments.service";
import { AssetsService } from "../../../services/assets.service";
import { OfflineService } from "../../../services/offline.service";
import { INVESTMENT_OBJECT, SuccessToastService } from "../../../services/success-toast.service";
import { Asset, Perimeter } from "../../../structs/assets";
import { Investment } from "../../../structs/investments";
import { Events } from "src/app/services/events.service";
import { Observable, Subscription, combineLatest } from "rxjs";
import * as R from "ramda";
import { map } from "rxjs/operators";
import { cleanFilterString } from "@structs";
import { SynthesisService } from "@services/synthesis.service";
import { ScopeService } from "@services/scope.service";
import { Risk } from "@structs/risk";
import { RiskService } from "@services/risks.service";
import { PerimetersService } from "@services/perimeters.service";

@Component({
  selector: "app-investment-modal",
  templateUrl: "./investment-modal.component.html",
  styleUrls: ["./investment-modal.component.scss"],
})
export class InvestmentModalComponent implements OnInit, OnDestroy {
  showInvestments: boolean = false;
  showCreationInvestments: boolean = false;
  showFAB: boolean = false;
  private perimeter: Perimeter = null; // Site
  private monoPerimeter: Perimeter;
  private asset: Asset = null;
  loading: boolean;
  public filteredInvestments$: Observable<Investment[]>;
  private subscriptions: Subscription[] = [];
  private risk: Risk = null;
  private investmentsWithRisks: number[] = [];

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    private investmentsService: InvestmentsService,
    private offlineApi: OfflineService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private events: Events,
    private successToast: SuccessToastService,
    private riskService: RiskService,
    private perimetersService: PerimetersService
  ) {}

  ngOnInit() {
    this.perimeter = this.navParams.get("perimeter");
    this.asset = this.navParams.get("asset");
    if (this.risk && this.monoPerimeter) {
      this.subscriptions.push(
        this.riskService.getPerimeterRisks(this.monoPerimeter.id).subscribe(risks => {
          this.investmentsWithRisks = risks.map(risk => risk.investment_ids[0]);
          this.searchInvestment();
        })
      );
    } else {
      this.searchInvestment();
    }
  }

  dismiss(newInvestment?: boolean, attached?: Investment) {
    let data = {
      newInvestment: newInvestment,
      attached: attached,
    };
    this.modalCtrl.dismiss(data);
  }

  async attachInvestment(investment: Investment) {
    if (this.risk) {
      this.dismiss(null, investment);
      return;
    }
    if (
      (investment.category && this.asset.category.id !== investment.category.id) ||
      (investment.subCategory && this.asset.subCategory.id !== investment.subCategory.id)
    ) {
      let nomenclatureAlert = await this.alertCtrl.create({
        header: this.translate.instant("For information"),
        subHeader: this.translate.instant("nomenclature_warning"),
        buttons: [
          {
            text: "OK",
            handler: () => {
              this.doAttachInvestment(investment);
            },
          },
        ],
      });
      await nomenclatureAlert.present();
    } else {
      this.showAttachConfirmation(investment);
    }
  }

  private async showAttachConfirmation(investment: Investment) {
    const attachConfirmation = await this.alertCtrl.create({
      header: this.translate.instant("Attach this investment?"),
      buttons: [
        {
          text: "OK",
          handler: () => {
            this.doAttachInvestment(investment);
          },
        },
        {
          text: this.translate.instant("Cancel"),
        },
      ],
    });
    await attachConfirmation.present();
  }

  private doAttachInvestment(investment: Investment) {
    this.subscriptions.push(
      this.investmentsService.attachInvestmentToAsset(investment, this.asset).subscribe(() => {
        this.events.publish("updateAssetsInvestmentsTotal", this.asset.investments.length);
        const investmentAttachedMessage = this.translate.instant("The investment has been attached");
        this.successToast.showSuccessToast(INVESTMENT_OBJECT, investmentAttachedMessage);
        this.dismiss(null, investment);
      })
    );
  }

  public getTitle() {
    let title: string;
    if (!this.showInvestments) {
      title = this.translate.instant("Attach an investment to the asset");
    } else if (this.showInvestments && this.showFAB) {
      title = this.perimeter.name + " > " + this.translate.instant("Attach an investment");
    } else {
      title = this.perimeter.name + " > " + this.translate.instant("New investment");
    }
    return title;
  }

  searchInvestment(event?) {
    let searchText = event ? cleanFilterString(event.target.value) : "";

    if (this.perimeter) {
      this.filteredInvestments$ = combineLatest([
        ...this.perimeter.sub_perimeters.map(subPerimeter =>
          this.offlineApi.getGlobalInvestments(subPerimeter.building_id)
        ),
      ]).pipe(
        map((investmentsBySubPerimeter: Investment[][]) => {
          return R.flatten(investmentsBySubPerimeter).filter(
            (inv: Investment) =>
              (!this.asset ||
                !inv.investmentType.replacement || // OR investment is not a replacement
                !AssetsService.assetHasReplacement(this.asset) || // Asset doesn't have any replacement yet
                !AssetsService.getAssetUsedReplacementLinks(this.asset).some(assetLink =>
                  inv.links.some(l => l.id === assetLink)
                )) && // OR investment links are available for the asset
              (!searchText || cleanFilterString(inv.label).includes(searchText)) &&
              (this.showCreationInvestments || !inv.investmentType.creationOfEquipment) &&
              // If coming from a risk, hide the investments that are already attached to a risk
              (!this.risk || !this.investmentsWithRisks.includes(inv.id)) &&
              this.checkMonoPerimeter(inv)
          );
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private checkMonoPerimeter(inv: Investment) {
    if (this.risk) {
      // If we want to attach an invest to a risk, we need to see the investments on this monoPerimeter
      // and its children.
      return this.perimetersService.investmentBelongsToPerimeterOrItsChildren(inv, this.monoPerimeter);
    } else {
      // If no risk, just check the monoPerimeter
      return !this.monoPerimeter || this.perimetersService.investmentBelongsToPerimeter(inv, this.monoPerimeter);
    }
  }
}
