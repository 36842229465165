import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NavController, AlertController, ModalController } from "@ionic/angular";

import { Asset, Category, Perimeter, SubCategory, SuperCategory } from "../../structs/assets";
import { TranslateService } from "@ngx-translate/core";
import { ScopeService } from "../../services/scope.service";
import { AssetsService } from "../../services/assets.service";
import { SearchService } from "../../services/search.service";
import { Investment } from "../../structs/investments";
import { Observable, Subscription, combineLatest } from "rxjs";
import { AssetSearch } from "../../structs/search";
import { Events } from "src/app/services/events.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AssetAddPage } from "../asset-add/asset-add.page";
import { Initiative } from "@structs";
import { PerimetersService } from "@services/perimeters.service";
import { AssetsFiltersComponent } from "src/app/components/assets-filters/assets-filters.component";

@Component({
  selector: "app-assets-list",
  templateUrl: "./assets-list.page.html",
  styleUrls: ["./assets-list.page.scss"],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AssetsListPage implements OnInit, OnDestroy {
  // TODO : The modal needs these parameters to be inputs
  // But if you open the page with the router, we will need
  // these as navigation parameters.
  @Input() title: string = "";
  @Input() selectMode: boolean = false;
  @Input() allowAllPerimeters: boolean = false;
  @Input() showAllAssets: boolean = false;
  @Input() investment: Investment = null;
  @Input() perimeter: Perimeter = null; // site
  @Input() showAddButton: boolean = true;
  @Input() initiative: Initiative = null;
  @Input() hideAsset: number = null; // The id of an asset we don't want to show in the list

  private assets: Array<Asset> = [];
  public message: string = "";
  public onlySuperCategory: SuperCategory = null;
  public onlyCategory: Category = null;
  public onlySubCategory: SubCategory = null;
  private buildingId: number = null;
  private loading: boolean = false;
  public searchText: string;
  public searchResults: Asset[] = [];

  public hasFiltersSelected: boolean = false;
  private hasFilterSelectedSubscription: Subscription;
  private currentMultiperimeter: Perimeter;
  private monoPerimeters: Perimeter[] = []; // the monoperimeter and its children
  private subscriptions: Subscription[] = [];
  private year: number;
  public showBackButton: boolean = false;
  private hasPreviousPage: boolean = null;

  constructor(
    public navCtrl: NavController,
    private translate: TranslateService,
    private scope: ScopeService,
    private event: Events,
    private alertCtrl: AlertController,
    private assetsApi: AssetsService,
    private modalCtrl: ModalController,
    private searchService: SearchService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.hasPreviousPage = !!this.router.getCurrentNavigation()?.previousNavigation;
    this.year = new Date().getFullYear();
    this.onlySuperCategory = this.investment?.superCategory || null;
    this.onlyCategory = this.investment?.category || null;
    this.onlySubCategory = this.investment?.subCategory || null;
    if (!this.title) {
      this.title = this.translate.instant("Assets");
    }
    if (!this.allowAllPerimeters) {
      // These 2 variables aren't the same. showAllAssets is a boolean for showing
      // or not showing the assets on other perimeters. allowAllPerimeters is
      // used to hide the toggle button.
      this.showAllAssets = false;
    }
    this.subscriptions.push(
      combineLatest([this.scope.getCurrentMultiPerimeter(), this.route.params]).subscribe(
        ([multiperimeter, params]) => {
          this.currentMultiperimeter = multiperimeter;
          if (this.initiative) {
            this.monoPerimeters = this.currentMultiperimeter.sub_perimeters.filter(
              monoPerimeter =>
                monoPerimeter.id === this.initiative.perimeter ||
                monoPerimeter.level_parent === this.initiative.perimeter
            );
          }
          if (params["monoPerimeterId"]) {
            const monoPerimeterIdString: string = params["monoPerimeterId"];
            this.monoPerimeters = [
              this.currentMultiperimeter.sub_perimeters.find(monoPerimeter => {
                if (monoPerimeterIdString.includes(".")) {
                  // localId. Offline perimeter
                  return monoPerimeter.localId === monoPerimeterIdString;
                } else {
                  // Online ID
                  return monoPerimeter.id === parseInt(monoPerimeterIdString);
                }
              }),
            ];
            this.showBackButton = true;
            this.showAllAssets = false;
            this.showAddButton = false;
          }
        }
      ),
      this.initAssetFilters().subscribe(() => {
        this.searchAssets();
        let observable: Observable<boolean> = this.searchService.assetsFiltersSelected;
        this.hasFilterSelectedSubscription = observable.subscribe(hasFiltersSelected => {
          this.hasFiltersSelected = hasFiltersSelected;
        });
      })
    );
    this.event.subscribe("newAsset", (newAsset: Asset) => {
      this.searchAssets();
    });
  }

  ngOnDestroy() {
    this.event.unsubscribe("newAsset");
    if (this.hasFilterSelectedSubscription) {
      this.hasFilterSelectedSubscription.unsubscribe();
    }
    this.hasFiltersSelected = false;
    this.searchService.clearAssetsFilters();
  }

  public cancel() {
    if (this.selectMode) {
      this.modalCtrl.dismiss({});
    }
  }

  public onAssetClicked(asset) {
    if (this.selectMode) {
      this.selectAsset(asset);
    } else {
      this.showAssetDetail(asset);
    }
  }

  private async selectAsset(asset: Asset) {
    if (
      (!this.onlyCategory || asset.category.id === this.onlyCategory.id) &&
      (!this.onlySubCategory || asset.subCategory.id === this.onlySubCategory.id)
    ) {
      this.modalCtrl.dismiss({ asset: asset });
    } else {
      const nomenclatureAlert = await this.alertCtrl.create({
        header: this.translate.instant("For information"),
        subHeader: this.translate.instant("nomenclature_warning"),
        buttons: [
          {
            text: "OK",
            handler: () => {
              this.modalCtrl.dismiss({ asset: asset });
            },
          },
        ],
      });
      await nomenclatureAlert.present();
    }
  }

  private showAssetDetail(asset: Asset) {
    this.router
      .navigate(["perimeters", this.currentMultiperimeter.id, "asset-detail", asset.id || asset.offlineId])
      .then();
  }

  public async addAsset() {
    let assetsModal = await this.modalCtrl.create({
      component: AssetAddPage,
      componentProps: {
        title: this.translate.instant("Attach an asset"),
        selectMode: true,
        allowAllPerimeters: true,
        investment: this.investment,
        runAsModalMode: true,
        autoSelectCategoryId: this.onlyCategory?.id,
        autoSelectSubCategoryId: this.onlySubCategory?.id,
        currentPerimeter: this.monoPerimeters.length === 1 ? this.monoPerimeters[0] : null,
      },
    });
    await assetsModal.present();
  }

  public searchByKeywords() {
    this.removeDuplicateChildren();
    if (this.searchText) {
      let assetList = this.assets;
      this.searchResults = this.assetsApi.searchAssetsByKeyWords(assetList, this.searchText);
    } else {
      this.initSearchResults();
    }
  }

  initSearchResults() {
    this.removeDuplicateChildren();
    this.searchResults = this.assets;
    this.searchResults = this.searchResults.sort((a, b) => {
      if (!a.lastAccess) {
        return 1;
      } else if (!b.lastAccess) {
        return -1;
      } else {
        const dateA = b.lastAccess instanceof Date ? a.lastAccess : new Date(a.lastAccess);
        const dateB = b.lastAccess instanceof Date ? b.lastAccess : new Date(b.lastAccess);
        return dateB.getTime() - dateA.getTime();
      }
    });
  }

  // Make sure we don't have duplicate asset in the list
  // (A children could be displayed inside + outside its parent...)
  private removeDuplicateChildren() {
    this.assets = this.assets.filter(
      elt =>
        !this.assets.some(asset => asset.children.some(c => c.offlineId === elt.offlineId && c.offline && elt.offline))
    );
  }

  public async openAssetsFilters() {
    const filtersModal = await this.modalCtrl.create({
      component: AssetsFiltersComponent,
    });
    filtersModal.present();
    await filtersModal.onDidDismiss().then(() => this.searchAssets());
  }

  searchAssets(refresher?) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.assets = [];
    this.subscriptions.push(
      this.searchService.searchAssets().subscribe(assets => {
        this.assets = assets.filter(asset => {
          // If investment is a replacement, don't show the asset if it already has a replacement
          return !this.investment?.investmentType.replacement || !AssetsService.assetHasReplacement(asset);
        });
        this.searchByKeywords();

        this.loading = false;
        if (refresher) {
          refresher.complete();
        }
      })
    );
  }

  private initAssetFilters(): Observable<void> {
    return new Observable(observer => {
      if (!this.hasFiltersSelected) {
        this.searchService.generateAssetsFilters(true).subscribe(
          filters => {
            this.searchService.clearAssetsFilters();
            this.setPerimetersFilter(filters);

            // Category filter
            let superCategoryFilter = filters.find(filter => filter instanceof AssetSearch.SuperCategorySearchFilter);
            let categoryFilter = filters.find(filter => filter instanceof AssetSearch.CategorySearchFilter);
            let subCategoryFilter = filters.find(filter => filter instanceof AssetSearch.SubCategorySearchFilter);
            let assetTypeFilter = filters.find(filter => filter instanceof AssetSearch.AssetTypeSearchFilter);

            if (this.onlySuperCategory) {
              superCategoryFilter.selectedValues = superCategoryFilter.availableValues.filter(
                val => val.id === this.onlySuperCategory.id
              );
              categoryFilter.availableValues = this.onlySuperCategory.children;
              categoryFilter.availableValuesLabels = categoryFilter.availableValues.map(cat => cat.name);
              categoryFilter.selectedValues = [];
              this.searchService.assetFilterSelected(superCategoryFilter);
            }
            if (this.onlyCategory) {
              categoryFilter.selectedValues = categoryFilter.availableValues.filter(
                val => val.id === this.onlyCategory.id
              );
              subCategoryFilter.availableValues = this.onlyCategory.children;
              subCategoryFilter.availableValuesLabels = subCategoryFilter.availableValues.map(subCat => subCat.name);
              subCategoryFilter.selectedValues = [];
              this.searchService.assetFilterSelected(categoryFilter);
            }
            if (this.onlySubCategory) {
              subCategoryFilter.selectedValues = subCategoryFilter.availableValues.filter(
                val => val.id === this.onlySubCategory.id
              );
              assetTypeFilter.selectedValues = this.onlySubCategory.children;
              assetTypeFilter.availableValuesLabels = assetTypeFilter.availableValues.map(assetType => assetType.name);
              assetTypeFilter.selectedValues = [];
              this.searchService.assetFilterSelected(subCategoryFilter);
            }

            observer.next();
            observer.complete();
          },
          err => {
            observer.error(err);
            observer.complete();
          },
          () => {
            observer.complete();
          }
        );
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  private setPerimetersFilter(filters) {
    // Perimeter filter
    let perimeterFilter = null;
    let childPerimeter: Perimeter = null;
    perimeterFilter = filters.find(filter => filter instanceof AssetSearch.PerimeterSearchFilter);

    if (perimeterFilter) {
      let selectedPerimeters: Perimeter[] = [];
      // If showAllAssets = true, we show the assets of all available perimeters
      if (this.showAllAssets) {
        selectedPerimeters = perimeterFilter.availableValues;
      } else {
        selectedPerimeters = perimeterFilter.availableValues.filter(val => {
          if (this.initiative) {
            // Select the initiative's perimeter and its children
            return val.id === this.initiative.perimeter || val.level_parent === this.initiative.perimeter;
          }
          if (this.investment) {
            return val.building_id === this.investment?.buildingId;
          }
          if (this.monoPerimeters.length === 1) {
            // Show the assets of a mono-perimeter
            const monoPerimeter = this.monoPerimeters[0];
            // If the monoPerimeter has a parent, we initialise the lvl1 perimeter filter
            // with it. Then we send the (child) monoPerimeter for the lvl2 perimeter filter.
            if (monoPerimeter.level_parent_local_id) {
              childPerimeter = monoPerimeter;
              return val.localId === monoPerimeter.level_parent_local_id;
            } else if (monoPerimeter.level_parent) {
              childPerimeter = monoPerimeter;
              return val.id === monoPerimeter.level_parent;
            } else if (monoPerimeter.localId) {
              return val.localId === monoPerimeter.localId;
            } else {
              return val.id === monoPerimeter.id;
            }
          }
        });
      }
      if (selectedPerimeters.length > 0) {
        perimeterFilter.selectedValues = selectedPerimeters;
        this.searchService.assetFilterSelected(perimeterFilter);
        this.searchService.updateLvl2PerimeterFilter(perimeterFilter);
        if (childPerimeter) {
          this.setChildrenPerimetersFilter(filters, childPerimeter);
        }
      }
    }
  }

  private setChildrenPerimetersFilter(filters, monoPerimeter: Perimeter) {
    let childrenPerimeterFilter = null;
    childrenPerimeterFilter = filters.find(filter => filter instanceof AssetSearch.PerimeterLvl2SearchFilter);
    if (childrenPerimeterFilter) {
      let selectedChildrenPerimeters = childrenPerimeterFilter.availableValues.filter(val => {
        if (monoPerimeter.localId) {
          return val.localId === monoPerimeter.localId;
        } else {
          return val.id === monoPerimeter.id;
        }
      });
      if (selectedChildrenPerimeters.length > 0) {
        childrenPerimeterFilter.selectedValues = selectedChildrenPerimeters;
        this.searchService.assetFilterSelected(childrenPerimeterFilter);
      }
    }
  }

  public backButtonClicked() {
    if (this.hasPreviousPage) {
      this.navCtrl.pop();
    } else {
      this.navCtrl.navigateBack(["perimeter"], { animated: false });
    }
  }

  toggleAllPerimeters() {
    this.setPerimetersFilter(this.searchService.assetsFilters);
    this.searchAssets();
  }
}
