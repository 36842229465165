import { Component, OnDestroy, OnInit } from "@angular/core";
import { IonItemSliding, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { SearchService } from "@services";
import { TaskSearch } from "@structs/search";
import { Subscription } from "rxjs";

@Component({
  selector: "app-tasks-filters",
  templateUrl: "./tasks-filters.component.html",
  styleUrls: ["./tasks-filters.component.scss"],
})
export class TasksFiltersComponent implements OnInit, OnDestroy {
  public defaultValueLabel: string;
  public filters: TaskSearch.TaskSearchFilter<any>[] = [];
  public enabledFilters: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private searchService: SearchService,
    private translate: TranslateService,
    private modalCtrl: ModalController
  ) {
    this.translate.get("All values").subscribe(defaultValueLabel => (this.defaultValueLabel = defaultValueLabel));
    this.searchService.generateTasksFilters().subscribe(filters => (this.filters = filters));
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.searchService.tasksFiltersSelected.subscribe(enabledFilters => (this.enabledFilters = enabledFilters))
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public dismiss(): void {
    this.modalCtrl.dismiss();
  }

  public clearFilters(): void {
    this.searchService.clearTasksFilters();
  }

  public clearFilter(item: IonItemSliding, filter: TaskSearch.TaskSearchFilter<any>): void {
    filter.clearSelected();
    item.close();
  }

  public onFilterSelected(filter: TaskSearch.TaskSearchFilter<any>): void {
    this.searchService.taskFilterSelected(filter);
  }
}
