import { Picture } from "./base";

export class RiskCategory {
  id: number;
  name: string;
  sub_categories: RiskSubCategory[];
}

export class RiskSubCategory {
  constructor(
    public category: number,
    public category_name: string,
    public criticality: number,
    public criticality_name: string,
    public help_text: string,
    public id: number,
    public name: string,
    public probability: number,
    public probability_label: string,
    public serverity_label: string,
    public severity: number
  ) {}
}

export class Risk {
  constructor(
    public criticality: number,
    public criticality_point: number,
    public help_text: string,
    public id: number,
    public images: RiskPicture[] | Picture[],
    public investment_ids: number[],
    public note: string,
    public probability: number,
    public severity: number,
    public sub_category: RiskSubCategory,
    public task_ids: Array<any>,
    public perimeter?: number
  ) {}
}

export class RiskPicture extends Picture {
  constructor(public id: number, public image: string, public thumbnail: string) {
    super(id, image, thumbnail, null, null, null, null);
  }
}

export enum RiskCriticality {
  MODERATE = 1,
  IMPORTANT = 2,
  MAJOR = 3,
}
