import { PerimeterType, Perimeter, AssetTypeLevel } from "./assets";
import { DocumentType, makeDocumentType } from "./documents";

export const ITEM_PLUS_CREATE_INVESTMENT: string = "create_investment";
export const ITEM_PLUS_CREATE_ASSET: string = "create_asset";
export const ITEM_PLUS_CREATE_DOCUMENT: string = "create_document";
export const ITEM_PLUS_CREATE_TASK: string = "create_task";
export const ITEM_ONLY_INVESTMENTS_LIST = "only_investment_list";
export const ITEM_ONLY_ASSETS_LIST = "only_assets_list";
export const ITEM_ONLY_DOCUMENTS_LIST = "only_documents_list";

export interface RoadmapIndicator {
  id: number;
  name: string;
  mnemonic: string;
  order: number;
  shown_in_report: boolean;
  technical_state_related: boolean;
}

export enum ControlPointStatuses {
  PENDING,
  ACKNOWLEDGED,
  NOT_RELEVANT,
}

export interface ControlPoint {
  id: number;
  /** Id. of the linked `Perimeter` */
  perimeter: number;
  /** Id. of the linked `Chapter` */
  chapter: number;
  /** Id. of the linked `Audit` */
  audit?: number;
  last_change_on?: Date;
  last_change_by?: string;
  status: ControlPointStatuses;
  /** The pause comment */
  pause_comment: string;
  perimeterLocalId: string;
  /** used for offline syncing */
  local_id?: number;
}

export class Theme {
  constructor(public id: number, public name: string) {}
}

export class AccessLevel {
  constructor(public id: number, public name: string) {}
}

export class AssetTypeForInstruction {
  constructor(public id: number, public name: string, public level: AssetTypeLevel) {}
}

export class SubCategoryForInstruction {
  constructor(public id: number, public name: string) {}
}

export class CategoryForInstruction {
  constructor(public id: number, public name: string) {}
}

export class ItemChoice {
  constructor(
    public id: number,
    public text: string,
    public selectedByDefault: boolean,
    public order: number,
    public nextItem: number,
    public isFinalStep: boolean
  ) {}
}

export class Item {
  constructor(
    public id: number,
    public text: string,
    public information: string,
    public index: number,
    public fullIndex: string,
    public type: string,
    public nextButton: boolean,
    public nextItem: number,
    public required: boolean,
    public maximumCharacters: number,
    public defaultValue: number,
    public minimumValue: number,
    public maximumValue: number,
    public showMonth: boolean,
    public showYear: boolean,
    public showPlusButton: string,
    public isFinalStep: boolean,
    public choices: Array<ItemChoice>,
    public defaultLabel: string,
    public instructionAssetType: AssetTypeForInstruction,
    public instructionSubCategory: SubCategoryForInstruction,
    public instructionCategory: CategoryForInstruction,
    public documentTypes: DocumentType[],
    public searchOnMultiPerimeter: boolean,
    public perimeterTypes: number[],
    public allowGlobalInvestment: boolean = false,
    public picturePrompt: boolean = false,
    public onlyLevels: string = ""
  ) {}
}

export class Chapter {
  constructor(
    public id: number,
    public subject: string,
    public label: string,
    public readOnly: boolean, // true if the user has only a read-only access-level
    public index: number,
    public fullIndex: string,
    public repeatIfNoAnswer: boolean,
    public onlyForClusters: number[],
    public onlyForTypes: number[],
    public zones: number[],
    public themes: number[],
    public accessLevels: number[],
    public items: Item[]
  ) {}
}

export class PauseReason {
  constructor(public id: number, public name: string) {}
}

/**
 * Used for posting Item answer value to the server
 */
export interface AnswerValue {
  item: number;
  value: any;
  ack_control_point: boolean;
}

export class Roadmap {
  constructor(
    public perimeterId: any = null, // site (multi-perimeter)
    public chapters: Chapter[] = [],
    public types: PerimeterType[] = [],
    public themes: Theme[] = [],
    public accessLevels: AccessLevel[] = [],
    public writeAccessLevels: AccessLevel[] = [],
    public pauseReasons: PauseReason[] = [],
    /*
     * answers is a two-levels map:
     * 1st level : for every sub-perimeter id, we have the 2nd level map
     * 2nd level : for every itemId, we have the Answer value for this perimeter
     */
    public answers: any = {},
    /*
     * prevAnswers is a two-levels map:
     * 1st level : for every sub-perimeter id, we have the 2nd level map
     * 2nd level : for every itemId, we have the answer value for this perimeter of the previous audit (prev year)
     * prevAnswers only contains the value of Notation items
     */
    public prevAnswers: any = {},
    /*
     * controlPoints is a two-levels map:
     * 1st level : for every sub-perimeter id, we have the 2nd level array
     * 2nd level : Array with id of completed chapters and last modify by and on information
     */
    public controlPoints: { [id: number]: ControlPoint[] } = {},
    /*
     * cancelledChapters is a two-levels map:
     * 1st level : for every sub-perimeter id, we have the 2nd level array
     * 2nd level : Array with id of cancelled chapters
     */
    public cancelledChapters: any = {}
  ) {}

  public getAnswerValue(perimeter: Perimeter, item: Item): any {
    let perimeterId = perimeter.id < 0 ? perimeter.localId : perimeter.id;
    return this.answers[perimeterId][item.id];
  }

  public hasAnswerValue(perimeter: Perimeter, item: Item): boolean {
    let perimeterId = perimeter.id < 0 ? perimeter.localId : perimeter.id;
    return this.answers[perimeterId] && item.id in this.answers[perimeterId];
  }

  /**
   * Verify if a control point is at status for the given perimeter
   *
   * @param perimeter
   * @param chapter
   * @param status
   */
  public isControlPointOnStatus(perimeter: Perimeter, chapter: Chapter, status: ControlPointStatuses): boolean {
    let perimeterId = perimeter.id < 0 ? perimeter.localId : perimeter.id;
    if (perimeterId in this.controlPoints) {
      let arrayOfControlPoints = this.controlPoints[perimeterId];
      if (arrayOfControlPoints) {
        return arrayOfControlPoints.find(cp => cp.chapter === chapter.id && cp.status === status) !== undefined;
      }
    }
    return false;
  }

  /**
   * Recover the control point linked to a perimeter and a chapter
   *
   * @param perimeter
   * @param chapter
   * @param status
   */
  public getControlPoint(perimeter: Perimeter, chapter: Chapter, status?: ControlPointStatuses): ControlPoint {
    let perimeterId = perimeter.id < 0 ? perimeter.localId : perimeter.id;
    if (perimeterId in this.controlPoints) {
      let arrayOfControlPoints = this.controlPoints[perimeterId];
      if (arrayOfControlPoints) {
        return arrayOfControlPoints.find(cp => cp.chapter === chapter.id && (status ? cp.status === status : true));
      }
    }
    return null;
  }

  public isControlPointCancelled(perimeter: Perimeter, chapter: Chapter): boolean {
    let perimeterId = perimeter.id < 0 ? perimeter.localId : perimeter.id;
    if (perimeterId in this.cancelledChapters) {
      let arrayOfCancelledChapters = this.cancelledChapters[perimeterId];
      if (arrayOfCancelledChapters) {
        return arrayOfCancelledChapters.indexOf(chapter.id) >= 0;
      }
    }
    return false;
  }

  public cancelControlPoint(perimeter: Perimeter, chapter: Chapter) {
    let perimeterId = perimeter.id < 0 ? perimeter.localId : perimeter.id;
    if (perimeterId in this.cancelledChapters) {
      let arrayOfCancelledChapters = this.cancelledChapters[perimeterId];
      if (!arrayOfCancelledChapters) {
        this.cancelledChapters[perimeterId] = [];
      }
      this.cancelledChapters[perimeterId].push(chapter.id);
    }
  }

  public isControlPointSkipped(perimeter: Perimeter, chapter: Chapter): boolean {
    return (
      (chapter.onlyForTypes.length > 0 && chapter.onlyForTypes.indexOf(perimeter.perimeterType) < 0) ||
      (chapter.onlyForClusters.length > 0 && chapter.onlyForClusters.indexOf(perimeter.cluster) < 0) ||
      this.isControlPointCancelled(perimeter, chapter)
    );
  }
}

/**
 * create Choice
 */
export function makeItemChoice(jsonData: any): ItemChoice {
  return new ItemChoice(
    jsonData.id,
    jsonData.text,
    jsonData.selected_by_default,
    jsonData.index,
    jsonData.next_item,
    jsonData.is_final_step
  );
}

/**
 * create Item
 */
export function makeItem(jsonData: any): Item {
  let choices: Array<ItemChoice> = [];
  if (jsonData.choices) {
    for (let i = 0; i < jsonData.choices.length; i++) {
      let item: ItemChoice = makeItemChoice(jsonData.choices[i]);
      choices.push(item);
    }
  }
  let assetType: AssetTypeForInstruction = null;
  if (jsonData.instruction_asset_type) {
    assetType = new AssetTypeForInstruction(
      jsonData.instruction_asset_type.id,
      jsonData.instruction_asset_type.name,
      jsonData.instruction_asset_type.level
    );
  }
  let subCategory: SubCategoryForInstruction = null;
  if (jsonData.instruction_sub_category) {
    subCategory = new SubCategoryForInstruction(
      jsonData.instruction_sub_category.id,
      jsonData.instruction_sub_category.name
    );
  }
  let category: CategoryForInstruction = null;
  if (jsonData.instruction_category) {
    category = new CategoryForInstruction(jsonData.instruction_category.id, jsonData.instruction_category.name);
  }
  let documentTypes: DocumentType[] = [];
  if (jsonData.document_types) {
    documentTypes = jsonData.document_types.map(elt => makeDocumentType(elt));
  }

  return new Item(
    jsonData.id,
    jsonData.text,
    jsonData.information,
    jsonData.index,
    jsonData.full_index,
    jsonData.type,
    jsonData.next_button,
    jsonData.next_item,
    jsonData.required,
    jsonData.maximum_characters,
    jsonData.default_value,
    jsonData.minimum_value,
    jsonData.maximum_value,
    jsonData.show_month,
    jsonData.show_year,
    jsonData.show_plus_button,
    jsonData.is_final_step,
    choices,
    jsonData.default_label,
    assetType,
    subCategory,
    category,
    documentTypes,
    !!jsonData.search_on_multi_perimeter,
    jsonData.perimeter_types || [],
    jsonData.allow_global_investment || false,
    jsonData.picture_prompt || false,
    jsonData.only_levels || ""
  );
}

/**
 * create a Chapter
 */
export function makeChapter(jsonData: any): Chapter {
  let items: Array<Item> = [];
  if (jsonData.items) {
    for (let i = 0; i < jsonData.items.length; i++) {
      let item: Item = makeItem(jsonData.items[i]);
      items.push(item);
    }
  }

  return new Chapter(
    jsonData.id,
    jsonData.subject,
    jsonData.label,
    jsonData.read_only,
    jsonData.index,
    jsonData.full_index,
    jsonData.repeat_if_no_answer,
    jsonData.only_for_clusters,
    jsonData.only_for_types,
    jsonData.zones,
    jsonData.themes,
    jsonData.access_levels,
    items
  );
}

/**
 * create a Roadmap
 */
export function makeRoadmap(perimeterId: number, jsonData: any): Roadmap {
  let chapters: Array<Chapter> = [];
  if (jsonData.chapters) {
    for (let i = 0; i < jsonData.chapters.length; i++) {
      let chapter: Chapter = makeChapter(jsonData.chapters[i]);
      chapters.push(chapter);
    }
  }

  return new Roadmap(
    perimeterId,
    chapters,
    jsonData.types,
    jsonData.themes,
    jsonData.access_levels,
    jsonData.write_access_levels,
    jsonData.pause_reasons,
    jsonData.answers,
    jsonData.prev_answers,
    jsonData.control_points,
    jsonData.cancelled_chapters
  );
}
