import { InvestmentSlice, Invoicing, Sponsorship } from "@structs";
import { Asset, QuantityUnit, RefrigerantType } from "./assets";
import { Document } from "./documents";

export type AssetEnergyConsumption = {
  asset_id: number;
  energy_consumption: number;
  perimeter_energy_consumption_id: number; // reverse key to the PerimeterEnergyConsumption
  assetOfflineId: number | string;
};

export type AssetEnergyConsumptionPayload = Pick<
  AssetEnergyConsumption,
  "asset_id" | "energy_consumption" | "assetOfflineId"
>;

export type PerimeterEnergyConsumption = {
  id: number;
  perimeter_consumption_id: number;
  asset_consumptions: AssetEnergyConsumption[];
  trajectory: number;
  year: number;
  energy_consumption: number;
  updated_on: string; // date format
};

export type EnergyType = {
  id: number;
  name: string;
  unit: string;
  order: number;
  is_default: boolean;
  is_electricity: boolean;
  color: string;
};

export type PerimeterEnergyTrajectory = {
  id: number;
  perimeter_consumptions: PerimeterEnergyConsumption[];
  energy_type: EnergyType;
  perimeter: number;
  area: number;
  declaration_month: number;
  declaration_updated_on: string; // date format
  is_mandatory: boolean;
};

export type ListPerimeterEnergyTrajectory = {
  id: number; // perimeter id
  name: string;
  code: string;
  trajectories: PerimeterEnergyTrajectory[];
}[];

export type MultiPerimeterEnergyTrajectory = {
  multi_perimeter_id: number;
  code: string;
  name: string;
  perimeters: ListPerimeterEnergyTrajectory;
};

export type EmissionCategory = {
  id: number;
  name: string;
  scope: number;
  is_calculated: boolean;
};

export type GainOrigin = {
  id: number;
  name: string;
};

export type GainPrecision = {
  id: number;
  name: string;
};

export interface InitiativeGain extends InitiativeBaseGain {
  gain_origin_id: GainOrigin["id"];
  gain_precision_id: GainPrecision["id"];
}

export type InitiativeBaseGain = {
  readonly id: number;
  readonly initiative_id: number;
  // foreign key
  energy_type_id: number;
  gain_origin: GainOrigin;
  gain_precision: GainPrecision;
  refrigerant_type_id: RefrigerantType["id"];
  // data
  theoretical_greenhouse_gas_savings_first_year: number;
  theoretical_greenhouse_gas_savings_full_year: number;
  theoretical_savings_first_year: number;
  theoretical_savings_full_year: number;
  real_greenhouse_gas_savings: number;
  real_savings: number;
  full_stop: boolean;
};

export type Initiative = {
  actual_completion_date: Date;
  assets: Asset[];
  budget: number;
  category: InitiativeCategory;
  cee_bonus: number;
  code: string;
  comments: string;
  confidence_on_feasibility: number;
  confidence_on_theoretical_savings: number;
  created_by: number;
  created_on: Date;
  description: string;
  documents: Document[];
  emission_category: EmissionCategory;
  energy_losses: string;
  id: number;
  initiative_type: number;
  invest_period: string;
  investment: InitiativeInvestment;
  label: string;
  owner: number;
  perimeter: number;
  reason: string;
  responsible: number;
  recurrency: boolean;
  savings_start_at: string;
  savings_starts_on: string;
  site: number;
  site_name: string;
  status: number;
  tags: number[];
  yearly_cost_savings: number;
  instances: InitiativeInstance[];
  initially_proposed_by: string;
  quantity: number;
  gains: InitiativeBaseGain[];
};

export interface InitiativeInvestment {
  id: number;
  createdOn: Date;
  asset: Asset;
  label: string;
  investmentType: number;
  initial_schedule: number;
  initial_schedule_to: number;
  final_schedule: number;
  final_schedule_to: number;
  quantity: number;
  priority: number;
  billingType: number;
  comments: string;
  localId: string;
  sponsorships: Sponsorship[];
  invoicing: Invoicing[];
  reasons: number[];
  slices: InvestmentSlice[];
  is_capex: boolean;
  is_estimation: boolean;
  status: number;
  scenario?: number;
  cached_payback: number;
}

export interface InitiativeInstance {
  initiative: number;
  scenario: number;
  status: number;
  savings_start_at: number;
}

export type InitiativeStatus = {
  id: number;
  name: string;
  cancelled: boolean;
  completed: boolean;
  hypothesis: boolean;
  to_be_specified: boolean;
  is_planned: boolean;
};

export enum GainType {
  NONE = "None",
  ENERGY = "ENERGY",
  CARBON = "CARBON",
  QUIT_FOSSIL = "QUIT_FOSSIL",
}

export type InitiativeType = {
  id: number;
  name: string;
  parent: number;
  default_confidence_on_feasibility: number;
  default_energy_type: number;
  default_recurrency: boolean;
  emission_category: number;
  quantity_unit: QuantityUnit;
  default_quantity: number;
  // from v5.0
  gain_type: GainType;
};

export type SavingSlice = {
  year: number;
  saving: number;
};

export type InitiativeTag = {
  id: number;
  name: string;
};

export enum InitiativeAddSteps {
  TYPE = 1,
  STATUS = 2,
  PERIMETER = 3,
  FINAL_STEP = 4,
}

export type InitiativeCategory = {
  id: number;
  name: string;
  is_calculated: boolean;
};
