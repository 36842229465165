<ion-content class="ion-padding">
  <div class="risk-header ion-margin-vertical ion-text-center">
    <ion-icon name="stop" class="risk-square" [color]="color"></ion-icon>
    <ion-text [color]="color">{{ riskSubCategory.name }}</ion-text>
  </div>
  <ion-textarea [readonly]="true" [value]="riskSubCategory.help_text" [autoGrow]="true"></ion-textarea>
</ion-content>
<ion-footer>
  <ion-row class="ion-justify-content-center">
    <ion-buttons>
      <ion-button fill="clear" (click)="close()">OK</ion-button>
    </ion-buttons>
  </ion-row>
</ion-footer>
