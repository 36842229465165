import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { RiskSubCategory } from "@structs/risk";

@Component({
  selector: "app-risk-informations",
  templateUrl: "./risk-informations.component.html",
  styleUrls: ["./risk-informations.component.scss"],
})
export class RiskInformationsComponent implements OnInit {
  @Input() riskSubCategory: RiskSubCategory = null;
  @Input() color: string = "";

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  public close() {
    this.modalCtrl.dismiss();
  }
}
