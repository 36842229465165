<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button *ngIf="enabledFilters" ion-button (click)="clearFilters()" class="clear-button">
        {{ "Clear" | translate }}
      </ion-button>
    </ion-buttons>
    <ion-title>{{ "Equipment filters" | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list lines="full" class="filters-list">
    <ion-item-sliding *ngFor="let filter of filters" #item>
      <ion-item color="{{ filter.selectedValues.length > 0 ? 'selected-filter' : null }}">
        <ion-label class="filter-label">{{ filter.label }}</ion-label>
        <ion-select
          class="filter-select"
          okText="{{ 'OK' | translate }}"
          cancelText="{{ 'Cancel' | translate }}"
          [multiple]="filter.multiple"
          [(ngModel)]="filter.selectedValues"
          (ngModelChange)="onFilterSelected(filter, $event)"
          [disabled]="filter.availableValues.length === 0"
          [placeholder]="defaultValueLabel">
          <ion-select-option *ngFor="let value of filter.availableValues; index as i" [value]="value">
            {{ filter.availableValuesLabels[i] }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item-options>
        <ion-item-option color="delete" (click)="clearFilter(item, filter)">
          <i class="fal fa-lg fa-eraser"></i>
        </ion-item-option>
        <ion-item-option
          *ngIf="showOnlyParentButton(filter)"
          color="{{ filter.onlyParent ? 'unknown' : 'mono-perimeter' }}"
          (click)="onlyParentPerimeterToggle(filter)">
          <i class="fal fa-lg fa-baby {{ filter.onlyParent ? 'icon-slash' : null }}"></i>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-list>
</ion-content>

<ion-footer>
  <div class="footer-container text-style1" *ngIf="filtersChanged">
    <ion-row class="ion-justify-content-center ion-align-items-center full-height">
      <ion-col size="8">
        <ion-button class="footer-button" (click)="applyFilters()" [disabled]="filtersTotal > 0 ? false : true">
          {{ "Apply" | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ion-footer>
