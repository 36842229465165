import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, from, Observable, Observer, of } from "rxjs";

import {
  AppSettings,
  Asset,
  AssetConfidenceChoice,
  AssetFloor,
  AssetType,
  AssetTypeLevel,
  AuditSynthesis,
  Category,
  cleanFilterString,
  getDataForAddAsset,
  getDataForPatchAsset,
  HeatTransferCoefficientU,
  makeAddAssetData,
  makeAsset,
  makeCategory,
  makeHeatTransferCoefficientU,
  makeNotationMissingComment,
  makePowerSource,
  makeRefrigerantType,
  makeSourceInfoHeatTransferCoefficientU,
  makeSourceInfoThermalResistanceCoefficientR,
  makeThermalResistanceCoefficientR,
  makeZone,
  MissingNotationReason,
  NotationMissingComment,
  OwnershipType,
  ParentAsset,
  patchAsset,
  Perimeter,
  PowerSource,
  RefrigerantType,
  SourceInfoHeatTransferCoefficientU,
  SourceInfoThermalResistanceCoefficientR,
  SubCategory,
  SuperCategory,
  ThermalResistanceCoefficientR,
  Zone,
} from "@structs";

import { BackendService } from "./backend.service";
import { OfflineService } from "./offline.service";
import { SynchronizationService } from "./synchronization.service";
import { SynthesisService } from "./synthesis.service";
import { TranslateService } from "@ngx-translate/core";
import { filter, map, mergeAll, switchMap, tap } from "rxjs/operators";
import { Events } from "./events.service";
import * as R from "ramda";
import { Change, ChangeAction, makeChange } from "@structs/synchronization";
import { InvestmentsService } from "./investments.service";

@Injectable()
export class AssetsService {
  constructor(
    private backend: BackendService,
    private offlineApi: OfflineService,
    private syncApi: SynchronizationService,
    private synthesis: SynthesisService,
    private translate: TranslateService,
    private investmentService: InvestmentsService
  ) {}

  public getNotationAlphaCoefficient(): Observable<number> {
    return new Observable(observer => {
      this.offlineApi.getConfig("notationAlphaCoefficient").subscribe(
        (jsonData: any) => {
          observer.next(jsonData);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getAppSettings(): Observable<AppSettings> {
    return new Observable(observer => {
      this.offlineApi.getConfig("appSettings").subscribe(
        (jsonData: any) => {
          observer.next(jsonData);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getSuperCategories(): Observable<SuperCategory[]> {
    return this.offlineApi.getConfig("superCategories");
  }

  public getCategories(): Observable<Category[]> {
    return this.getSuperCategories().pipe(
      map((superCategories: SuperCategory[]) => {
        return R.flatten(
          superCategories.map(superCategory => superCategory.children.map(category => makeCategory(category)))
        );
      })
    );
  }

  /**
   * When you only have an asset's subcategory but you need its category
   * @param subCategoryId
   * @returns
   */
  public getCategoryFromSubCategory(subCategoryId: number): Observable<Category> {
    return new Observable(observer => {
      let index: number;
      let category: Category;
      this.getCategories().subscribe(
        categories => {
          categories.forEach(cat => {
            index = cat.children.findIndex(subCat => subCat.id === subCategoryId);
            if (index !== -1) {
              category = cat;
            }
          });
          observer.next(category);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  public getZones(): Observable<Zone[]> {
    return new Observable(observer => {
      this.offlineApi.getConfig("zones").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          observer.next(data.map(item => makeZone(item)));
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getOwnershipTypes(): Observable<OwnershipType[]> {
    return new Observable(observer => {
      this.offlineApi.getConfig("ownershipTypes").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          observer.next(data);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getConfidenceChoices(): Observable<AssetConfidenceChoice[]> {
    return new Observable(observer => {
      this.offlineApi.getConfig("assetInstallationConfidences").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          observer.next(data);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getPowerSources(): Observable<PowerSource[]> {
    return new Observable(observer => {
      this.offlineApi.getConfig("assetPowerSource").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          let powerSources: PowerSource[] = [];
          for (let i = 0, l = data.length; i < l; i++) {
            powerSources.push(makePowerSource(data[i]));
          }
          observer.next(powerSources);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getThermalResistanceCoefficientR(): Observable<ThermalResistanceCoefficientR[]> {
    return new Observable(observer => {
      this.offlineApi.getConfig("thermalResistanceCoefficientR").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          let thermalResistanceCoefficientR: ThermalResistanceCoefficientR[] = [];
          for (let i = 0, l = data.length; i < l; i++) {
            thermalResistanceCoefficientR.push(makeThermalResistanceCoefficientR(data[i]));
          }
          observer.next(thermalResistanceCoefficientR);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getHeatTransferCoefficientU(): Observable<HeatTransferCoefficientU[]> {
    return new Observable(observer => {
      this.offlineApi.getConfig("heatTransferCoefficientU").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          let heatTransferCoefficientU: HeatTransferCoefficientU[] = [];
          for (let i = 0, l = data.length; i < l; i++) {
            heatTransferCoefficientU.push(makeHeatTransferCoefficientU(data[i]));
          }
          observer.next(heatTransferCoefficientU);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getSourceInfoThermalResistanceCoefficientR(): Observable<SourceInfoThermalResistanceCoefficientR[]> {
    return new Observable(observer => {
      this.offlineApi.getConfig("sourceInfoThermalResistanceCoefficientR").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          let sourceInfoThermalResistanceCoefficientR: SourceInfoThermalResistanceCoefficientR[] = [];
          for (let i = 0, l = data.length; i < l; i++) {
            sourceInfoThermalResistanceCoefficientR.push(makeSourceInfoThermalResistanceCoefficientR(data[i]));
          }
          observer.next(sourceInfoThermalResistanceCoefficientR);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getSourceInfoHeatTransferCoefficientU(): Observable<SourceInfoHeatTransferCoefficientU[]> {
    return new Observable(observer => {
      this.offlineApi.getConfig("sourceInfoHeatTransferCoefficientU").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          let sourceInfoHeatTransferCoefficientU: SourceInfoHeatTransferCoefficientU[] = [];
          for (let i = 0, l = data.length; i < l; i++) {
            sourceInfoHeatTransferCoefficientU.push(makeSourceInfoHeatTransferCoefficientU(data[i]));
          }
          observer.next(sourceInfoHeatTransferCoefficientU);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getRefrigerantTypes(): Observable<RefrigerantType[]> {
    return new Observable(observer => {
      this.offlineApi.getConfig("refrigerantTypes").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          let refrigerantTypes: RefrigerantType[] = [];
          for (let i = 0, l = data.length; i < l; i++) {
            refrigerantTypes.push(makeRefrigerantType(data[i]));
          }
          observer.next(refrigerantTypes);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public addAsset(monoPerimeter: Perimeter, data: any, perimeterLocalId: string = ""): Observable<Asset> {
    return new Observable(observer => {
      // We consider that it will fail
      // So we store a change and an offline asset

      let url: string = "/audit/api/assets/";

      // Get a local Id (offlineId)
      this.offlineApi.getNextOfflineId().subscribe(
        (offlineAssetsNextId: number) => {
          let assetData: any = makeAddAssetData(monoPerimeter, data, offlineAssetsNextId);
          assetData.parent = null;
          let offlineAsset: Asset = makeAsset(assetData);
          // force the notes : they will be sent in a following API call
          // but we need it on the asset to make sure to display everything properly
          offlineAsset.notes = data.notes;
          offlineAsset.ratingReasons = data.ratingReasons;
          offlineAsset.level = data.level;
          offlineAsset.lastAccess = new Date();
          let addData = getDataForAddAsset(monoPerimeter, data);
          addData.local_id = "" + offlineAssetsNextId;
          if (data.parent) {
            offlineAsset.parent = new ParentAsset(data.parent.id, data.parent.offline, data.parent.offlineId);
            if (data.parent.id === 0) {
              // offline parent : let's try to update it
              this.offlineApi.getAssetIdsMap().subscribe(assetIdsMap => {
                const parentOfflineId = data.parent.offlineId;
                if (parentOfflineId && parentOfflineId in assetIdsMap) {
                  let parentId = assetIdsMap[parentOfflineId];
                  addData.parent = parentId;
                  offlineAsset.parent.id = parentId;
                  offlineAsset.parent.offline = false;
                }
                this._doAddAsset(url, addData, offlineAsset, perimeterLocalId, observer);
              });
            } else {
              // online parent : the parentId is correct
              this._doAddAsset(url, addData, offlineAsset, perimeterLocalId, observer);
            }
          } else {
            // No parent : no need to get the parentId
            this._doAddAsset(url, addData, offlineAsset, perimeterLocalId, observer);
          }
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   * Update an asset
   * @param asset: the asset (before the change)
   * @param data : data for the update
   * @returns Observable on the asset update
   */
  public _doPatchAsset(asset: Asset, data: any): Observable<Asset> {
    const patchData: any = getDataForPatchAsset(data);
    const url = "/audit/api/assets/" + asset.id + "/";
    let monoPerimeterLocalId = "";
    if (data.hasOwnProperty("perimeterLocalId")) {
      monoPerimeterLocalId = data.perimeterLocalId;
    }
    const patchedAsset: Asset = patchAsset(asset, data);
    patchedAsset.offline = asset.offline;
    return this.syncApi
      .addChange(
        makeChange(ChangeAction.saveAssetAction, url, "patch", patchData, patchedAsset, "", null, monoPerimeterLocalId)
      )
      .pipe(switchMap(() => this.offlineApi.storeAsset(patchedAsset)));
  }

  private deleteOneAsset(asset: Asset): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      let url: string = "/audit/api/assets/" + asset.id + "/";
      this.syncApi.addChange(makeChange(ChangeAction.deleteAssetAction, url, "delete", {}, asset)).subscribe(
        () => {
          this.offlineApi.removeAsset(asset).subscribe(
            () => {},
            () => {},
            () => {
              this._pushChanges(observer);
            }
          );
          // This event is used to quickly update the total number of assets
          // this.event.publish('totalAssets', -1);
        },
        err => {
          observer.error(err);
          observer.next(false);
          observer.complete();
        }
      );
    });
  }

  public deleteAsset(asset: Asset): Observable<boolean> {
    // If the asset has investments, we delete those with hypothesis status and store
    // the other investments as global investments.
    // The backend already stores the investments as globals, but we need
    // to see it locally without having to synchronise/reload the synthesis
    return new Observable((observer: Observer<boolean>) => {
      let updatedAsset: Asset = asset;
      let investmentsToDelete = [];
      updatedAsset.investments = asset.investments.filter(invest => {
        if (invest.status.hypothesis) {
          investmentsToDelete.push(this.investmentService.deleteInvestment(asset, invest));
          return false;
        } else {
          return true;
        }
      });
      let deleteInvestments$: Observable<any> = of(null);
      if (investmentsToDelete.length > 0) {
        deleteInvestments$ = forkJoin(investmentsToDelete);
      }
      deleteInvestments$
        .pipe(
          switchMap(() => {
            return this.storeInvestmentsAsGlobals(updatedAsset);
          })
        )
        .subscribe(() => {
          let assetChildren = asset.children;
          let countdown = assetChildren.length;
          let assetParent = asset.parent;
          let assetId = asset.id;
          let assetOfflineId = asset.offlineId;
          // Delete the asset
          this.deleteOneAsset(asset).subscribe(
            value => {
              if (assetChildren.length === 0) {
                // If the asset doesn't have children
                if (assetParent) {
                  // but if it has a parent, we need to update its parent to remove
                  // this one from the children list
                  // load the parent
                  this.offlineApi.loadAsset(assetParent.id, assetParent.offlineId).subscribe((parent: Asset) => {
                    let newChildren = [];
                    // delete from the children
                    for (let child of parent.children) {
                      if (
                        (child.id && child.id === assetId) ||
                        (child.offlineId && child.offlineId === assetOfflineId)
                      ) {
                        // ignore
                      } else {
                        newChildren.push(child);
                      }
                    }
                    // save the parent
                    parent.children = newChildren;
                    this.offlineApi.storeAsset(parent).subscribe(() => {
                      // Ok we can return
                      observer.next(value);
                      observer.complete();
                    });
                  });
                } else {
                  // No parent and no children. Everything is OK, we can return.
                  observer.next(value);
                  observer.complete();
                }
              } else {
                // If the asset has children, we need to delete its children too
                // for every child
                from(assetChildren)
                  .pipe(map(asset => this.deleteOneAsset(asset)))
                  .pipe(mergeAll())
                  .subscribe(
                    () => {},
                    () => {},
                    () => {
                      observer.next(value);
                      observer.complete();
                    }
                  );
              }
            },
            err => {
              observer.next(err);
              observer.complete();
            }
          );
        });
    });
  }

  public deleteSeveralAssets(assets: Asset[]) {
    assets.forEach(asset => {
      this.deleteAsset(asset).subscribe();
    });
  }

  /**
   * get an asset from backend
   */
  public getAsset(assetId: number): Observable<Asset> {
    return new Observable(observer => {
      this.backend.get("/audit/api/assets/" + assetId + "/").subscribe(
        jsonData => {
          observer.next(makeAsset(jsonData));
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   * create or update the note of an asset during an audit
   * @param asset
   * @returns Observable<boolean> : success of the operation
   */
  public addAssetAccess(asset: Asset): Observable<boolean> {
    let url = "/audit/api/assets/" + asset.id + "/access/";
    return new Observable(observer => {
      // If the asset if offline (not yet on server), keep things local
      let change: Change = makeChange(ChangeAction.assetAccessAction, url, "post", {}, asset);
      this.syncApi.addChange(change).subscribe(
        () => {
          this.synthesis.updateAssetAccessInSynthesis(asset).subscribe(
            () => {
              // Push changes
              // silent: true : do not toast a message in case of error
              this.syncApi.signalOfflineChanges(true).subscribe(
                () => {},
                () => {},
                () => {
                  observer.next(true);
                  observer.complete();
                }
              );
            },
            err => {
              observer.error(err);
              observer.complete();
            }
          );
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  private _doAddAsset(
    url: string,
    addData: any,
    offlineAsset: Asset,
    perimeterLocalId: string,
    observer: Observer<Asset>
  ): void {
    // Save a change for later synchronization
    this.offlineApi.storeAsset(offlineAsset).subscribe(
      () => {
        this.syncApi
          .addChange(
            makeChange(ChangeAction.addAssetAction, url, "post", addData, offlineAsset, "", null, perimeterLocalId)
          )
          .subscribe(
            () => {
              // Try to synchronize the changes
              this.syncApi.signalOfflineChanges().subscribe(
                () => {
                  observer.next(offlineAsset);
                  observer.complete();
                },
                err => {
                  observer.error(err);
                  observer.complete();
                }
              );
            },
            err => {
              observer.error(err);
              observer.complete();
            }
          );
      },
      err => {
        observer.error(err);
        observer.complete();
      }
    );
  }

  private _pushChanges(observer: Observer<boolean>): void {
    // Push changes
    this.syncApi.signalOfflineChanges().subscribe(
      () => {
        observer.next(true);
        observer.complete();
      },
      () => {
        observer.next(true);
        observer.complete();
      }
    );
  }

  public getMissingNotationReasonLabel(value: MissingNotationReason): string {
    switch (value) {
      case MissingNotationReason.NOT_SET:
        return "";
      case MissingNotationReason.STOPPED_ABNORMAL:
        return this.translate.instant("Stopped Equipment (abnormal)");
      case MissingNotationReason.STOPPED_INSTRUCTION:
        return this.translate.instant("Stopped Equipment (instruction)");
      case MissingNotationReason.BROKEN:
        return this.translate.instant("Broken equipment");
      case MissingNotationReason.ACCESS:
        return this.translate.instant("Inaccessible equipment");
      case MissingNotationReason.WAITING_EVALUATION:
        return this.translate.instant("Waiting evaluation");
      case MissingNotationReason.OTHER:
        return this.translate.instant("Other");
      case MissingNotationReason.MANAGER_UNAVAILABLE:
        return this.translate.instant("Technical manager unavailable");
      case MissingNotationReason.MANAGER_DOESNT_KNOW:
        return this.translate.instant("Technical manager does not know");
      default:
        // should never occur
        return this.translate.instant("Unknown");
    }
  }

  public getNotationMissingComments(): Observable<NotationMissingComment[]> {
    return new Observable(observer => {
      this.offlineApi.getConfig("notationMissingComments").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          let reasons: NotationMissingComment[] = [];
          for (let i = 0, l = data.length; i < l; i++) {
            reasons.push(makeNotationMissingComment(data[i]));
          }
          observer.next(reasons);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  // returns the list of potential parent for a given asset
  // parents must be asset of the same perimeter and follow rules for asset types
  public getPotentialParents(child: Asset): Observable<Asset[]> {
    return new Observable(observer => {
      this.getAssetTypeSubCategory(child.assetType).subscribe(subCategory => {
        this.offlineApi.getAuditSynthesisEx().subscribe(
          (auditSynthesis: AuditSynthesis) => {
            const potentialParents = [];
            for (const asset of auditSynthesis.assets) {
              let childPerimeter = child.building.monosite_perimeter;
              let assetPerimeter = asset.building.monosite_perimeter;
              let hasSamePerimeter =
                (childPerimeter.id && assetPerimeter.id === childPerimeter.id) ||
                (childPerimeter.localId && assetPerimeter.localId === childPerimeter.localId);
              if (
                hasSamePerimeter && // same perimeter
                asset.subCategory.id === subCategory.id && // same sub-category
                asset.assetType.id !== child.assetType.id && // same sub-category but different asset type
                ((child.assetType.level === AssetTypeLevel.LEVEL_COLLECTION_ITEM &&
                  asset.assetType.level === AssetTypeLevel.LEVEL_COLLECTION) ||
                  (child.assetType.level === AssetTypeLevel.LEVEL_COMPONENT &&
                    asset.assetType.level === AssetTypeLevel.LEVEL_UNIT))
              ) {
                potentialParents.push(asset);
              }
            }
            observer.next(potentialParents);
            observer.complete();
          },
          err => {
            observer.error(err);
            observer.complete();
          }
        );
      });
    });
  }

  // returns the category from an asset type
  public getAssetTypeCategory(assetType: AssetType): Observable<Category> {
    return new Observable(observer => {
      this.getCategories().subscribe(
        categories => {
          for (let category of categories) {
            for (let subCategory of category.children) {
              for (let assetTypeElt of subCategory.children) {
                if (assetType.id === assetTypeElt.id) {
                  observer.next(category);
                  observer.complete();
                  return;
                }
              }
            }
          }
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   * returns the sub-category from an asset type.
   * 2 parameters, because sometimes we don't even have the asset type
   * but an asset type ID.
   * @param assetType
   * @param assetTypeId
   * @returns
   */
  public getAssetTypeSubCategory(assetType?: AssetType, assetTypeId?: number): Observable<SubCategory> {
    return this.getCategories().pipe(
      map(categories => R.flatten(categories.map(cat => cat.children))),
      map((subCategories: SubCategory[]) =>
        subCategories.find(subCategory => {
          const subCat = subCategory.children.find(type => type.id === assetType?.id || type.id === assetTypeId);
          return subCat;
        })
      )
    );
  }

  // returns the list of asset types allowed for the parent of an asset of the given asset type
  public getParentAssetTypes(assetType: AssetType): Observable<AssetType[]> {
    return new Observable(observer => {
      this.getAssetTypeSubCategory(assetType).subscribe(
        subCategory => {
          let parents = [];
          for (let sibling of subCategory.children) {
            if (sibling.id !== assetType.id) {
              if (
                assetType.level === AssetTypeLevel.LEVEL_COLLECTION_ITEM &&
                sibling.level === AssetTypeLevel.LEVEL_COLLECTION
              ) {
                parents.push(sibling);
              }

              if (assetType.level === AssetTypeLevel.LEVEL_COMPONENT && sibling.level === AssetTypeLevel.LEVEL_UNIT) {
                parents.push(sibling);
              }
            }
          }
          observer.next(parents);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  // returns the list of asset types allowed as child of an asset of the given asset type
  public getChildAssetTypes(assetType: AssetType): Observable<AssetType[]> {
    return new Observable(observer => {
      this.getAssetTypeSubCategory(assetType).subscribe(
        subCategory => {
          let childTypes = [];
          if (assetType.level === AssetTypeLevel.LEVEL_NONE) {
            childTypes.push(assetType);
          } else {
            for (let sibling of subCategory.children) {
              if (sibling.id !== assetType.id) {
                if (
                  assetType.level === AssetTypeLevel.LEVEL_COLLECTION &&
                  sibling.level === AssetTypeLevel.LEVEL_COLLECTION_ITEM
                ) {
                  childTypes.push(sibling);
                }

                if (assetType.level === AssetTypeLevel.LEVEL_UNIT && sibling.level === AssetTypeLevel.LEVEL_COMPONENT) {
                  childTypes.push(sibling);
                }
              }
            }
          }
          observer.next(childTypes);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   * Get all available assetFloor from the platform
   * @returns Observable<AssetFloor[]>
   */
  public assetFloor$(): Observable<AssetFloor[]> {
    return this.offlineApi.getConfig("assetFloor").pipe(filter(floors => !!floors)) as Observable<AssetFloor[]>;
  }

  public searchAssetsByKeyWords(assetList: Asset[], searchText: string) {
    searchText = cleanFilterString(searchText);
    let searchResults = assetList.filter(asset => {
      if (AssetsService.doesElementMatch(asset, searchText)) {
        return true;
      } else {
        return asset.children.filter(child => AssetsService.doesElementMatch(child, searchText)).length > 0;
      }
    });
    return searchResults;
  }

  public static doesElementMatch(asset: Asset, query: string): boolean {
    const stringsToTest: string[] = [
      asset.label || "",
      asset.category?.name || "",
      asset.subCategory?.name || "",
      asset.assetType?.name || "",
      ...asset.assetType.keywords.map(keyword => keyword.name),
      asset.maintenanceCode || "",
    ];
    return !!stringsToTest.find(string => AssetsService.anyWordBeginWith(string, query));
  }

  /**
   * Check that every word of the query is present in the string
   * (Each word of the query must be the beginning of a word in the string)
   * @param string The string in which we search the query
   * @param query The query we search in the string
   * @returns boolean
   */
  public static anyWordBeginWith(string: string, query: string): boolean {
    const cleanString = cleanFilterString(string);
    const cleanQuery = cleanFilterString(query);
    const stringWords = cleanString ? cleanString.split(" ") : null;
    const queryWords = cleanQuery.split(" ");
    return queryWords.every(queryWord => !!stringWords?.find(stringWord => stringWord.startsWith(queryWord)));
  }

  /**
   * Store the asset's investments as global investments
   */
  public storeInvestmentsAsGlobals(asset: Asset): Observable<boolean> {
    return new Observable(observer => {
      // Getting the asset's investments
      let investments = [];
      investments = investments.concat(
        asset.investments.map(investment => {
          investment.assetId = null;
          investment.assetOffline = false;
          investment.building = asset.building;
          investment.monoPerimeterLocalId = asset.building.monosite_perimeter.localId;
          investment.category = asset.category;
          investment.subCategory = asset.subCategory;
          return investment;
        })
      );
      if (asset.children && asset.children.length > 0) {
        // Getting the children's investments
        asset.children.forEach(child => {
          child.investments.forEach(investment => {
            investment.assetId = null;
            investment.assetOffline = false;
            investment.building = child.building;
            investment.monoPerimeterLocalId = child.building.monosite_perimeter.localId;
            investment.category = child.category;
            investment.subCategory = child.subCategory;
            investments.push(investment);
          });
        });
      }
      if (investments.length > 0) {
        this.offlineApi.storeGlobalInvestmentsArray(investments).subscribe(() => {
          observer.next(true);
          observer.complete();
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  public static getAssetEndOfLife(asset: Asset): number {
    let eol: number = asset.installationYear + asset.expectedLifetime;

    // Get last asset notation
    const last_asset_notation_date: Date = new Date(asset.technical_state_changed_on);

    asset.investments
      .filter(inv => !inv.status?.hypothesis)
      .map(inv => {
        if (inv.investmentType.replacement) {
          eol = inv.finalScheduleTo;
        } else if (
          inv.lifetimeAfterInvestment &&
          (!last_asset_notation_date || inv.finalScheduleTo >= last_asset_notation_date.getFullYear())
        ) {
          const invest_eol = inv.finalScheduleTo + inv.lifetimeAfterInvestment;
          eol = invest_eol || eol;
        }
      });

    return eol;
  }

  // Returns true if the asset has a replacement investment
  public static assetHasReplacement(asset: Asset): boolean {
    return !!asset.investments?.some(inv => inv.investmentType.replacement);
  }

  // Returns the investment link ids already used for the asset replacements
  public static getAssetUsedReplacementLinks(asset: Asset): number[] {
    return R.flatten(asset.investments?.map(inv => inv.links.map(link => link.id)));
  }

  // Check if this asset type is allowed for this perimeter type and this cluster
  public isAssetTypeAllowedForPerimeter(assetType: AssetType, perimeterTypeId: number, clusterId: number): boolean {
    return (
      (!assetType.onlyForPerimeterTypes?.length || assetType.onlyForPerimeterTypes.includes(perimeterTypeId)) &&
      (!assetType.onlyForClusters?.length || assetType.onlyForClusters.includes(clusterId))
    );
  }
}
