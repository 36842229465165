import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IonItemSliding, ModalController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { SearchService } from "../../services/search.service";
import { AssetSearch } from "../../structs/search";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "assets-filters",
  templateUrl: "./assets-filters.component.html",
  styleUrls: ["./assets-filters.component.scss"],
})
export class AssetsFiltersComponent implements OnInit, OnDestroy {
  public defaultValueLabel: string;
  public filters: AssetSearch.AssetSearchFilter<any>[] = [];
  public enabledFilters: boolean = false;
  public filtersTotal: number = 0;
  public filtersChanged: boolean = false;
  private filtersObj: { [key: string]: AssetSearch.AssetSearchFilter<any>[] } = {};
  private subscriptions: Subscription[] = [];

  constructor(
    private searchService: SearchService,
    private translate: TranslateService,
    private modalCtrl: ModalController
  ) {
    this.translate.get("All values").subscribe(defaultValueLabel => (this.defaultValueLabel = defaultValueLabel));
    this.searchService.generateAssetsFilters().subscribe(filters => {
      this.filters = filters;
    });
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.searchService.assetsFiltersSelected.subscribe(enabledFilters => {
        this.enabledFilters = enabledFilters;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public dismiss(): void {
    this.modalCtrl.dismiss();
  }

  public clearFilters(): void {
    this.searchService.clearAssetsFilters();
  }

  public clearFilter(item: IonItemSliding, filter: AssetSearch.AssetSearchFilter<any>): void {
    filter.clearSelected();
    this.searchService.assetFilterSelected(filter);
    this.searchService.updateAssetsTechnicalCategoriesFilter(filter, true);
    this.searchService.updateLvl2PerimeterFilter(filter);
    item.close();
  }

  public onFilterSelected(filter: AssetSearch.AssetSearchFilter<any>, event): void {
    // Since the current design only supported the `selectedValues` to be an Array
    // we have to cast the object into an Array of single element
    if (!filter.multiple) filter.selectedValues = [event];

    const filterType = filter.label;
    let filterArray = this.filtersObj[filterType];

    if (!filterArray) {
      filterArray = [];
      this.filtersObj[filterType] = filterArray;
    }
    filterArray.push(filter);
    this.filtersTotal = Object.keys(this.filtersObj).length;
    // If a parent category has been selected, update the available values for the child category
    this.searchService.updateAssetsTechnicalCategoriesFilter(filter);
    this.searchService.updateLvl2PerimeterFilter(filter);
    this.filtersChanged = true;
  }

  public applyFilters(): void {
    for (let filterType in this.filtersObj) {
      const filterArray = this.filtersObj[filterType];
      filterArray.forEach(filter => this.searchService.assetFilterSelected(filter));
    }
    this.dismiss();
  }

  public showOnlyParentButton(filter: AssetSearch.AssetSearchFilter<any>) {
    return filter instanceof AssetSearch.PerimeterSearchFilter;
  }

  public onlyParentPerimeterToggle(filter: AssetSearch.AssetSearchFilter<any>) {
    filter.onlyParent = !filter.onlyParent;
  }
}
