import { ScopeService } from "@services/scope.service";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { SearchService } from "../../services/search.service";
import { Investment } from "../../structs/investments";
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from "rxjs";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { Initiative, InvestmentSearch, Perimeter, cleanFilterString } from "@structs";
import { filter, first, map, startWith, switchMap, tap } from "rxjs/operators";
import { InvestmentsService } from "@services/investments.service";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { TranslateService } from "@ngx-translate/core";
import { InitiativeService } from "@services";
import { PerimetersService } from "@services/perimeters.service";
import { InvestmentsFiltersComponent } from "src/app/components/investments-filters/investments-filters.component";
import { Risk } from "@structs/risk";
import { ConfigService } from "@services/config.service";
import { RiskService } from "@services/risks.service";

@Component({
  selector: "app-investments-list",
  templateUrl: "./investments-list.page.html",
  styleUrls: ["./investments-list.page.scss"],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class InvestmentsListPage implements OnInit, OnDestroy {
  // TODO : The modal needs these parameters to be inputs
  // But if you open the page with the router, we will need
  // these as navigation parameters.
  @Input() title: string = this.translate.instant("Investments");
  @Input() selectMode: boolean = false;
  @Input() allowAllPerimeters: boolean = false;
  @Input() initiative: Initiative;
  @Input() multiPerimeterId: number;
  @Input() showAddButton: boolean = false;
  @Input() risksList: Risk[] = null;
  @Input() monoPerimeterId: number = null;
  @Input() showFilters: boolean = true;

  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

  private investments$ = new BehaviorSubject<Investment[]>([]);
  private initiatives$: Observable<Initiative[]>;
  private searchText$: BehaviorSubject<string> = new BehaviorSubject(null);
  private subscriptions: Subscription[] = [];
  private lastCreatedInvestmentLocalId = "";

  public filteredInvestments$: Observable<Investment[]>;
  public hasFiltersSelected$: Observable<boolean> = this.searchService.investmentsFiltersSelected;
  public loading: boolean = false;
  public searchText: string;
  public showBackButton: boolean = false;
  private hasPreviousPage: boolean = null;
  private childPerimeter: Perimeter = null;
  private parentPerimeter: Perimeter = null;
  private siteRisks$: Observable<Risk[]>;

  constructor(
    private searchService: SearchService,
    private router: Router,
    private investmentsService: InvestmentsService,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private scope: ScopeService,
    private initiativeService: InitiativeService,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private perimetersService: PerimetersService,
    private configService: ConfigService,
    private riskService: RiskService
  ) {}

  public ngOnInit(): void {
    this.hasPreviousPage = !!this.router.getCurrentNavigation()?.previousNavigation;
    this.initiatives$ = this.initiativeService.getInitiatives(this.multiPerimeterId);
    this.siteRisks$ = this.configService.customerConfig$.pipe(
      filter(config => config.risk_module_enabled),
      switchMap(riskModuleEnabled => this.riskService.siteRisks$)
    );
    this.subscriptions.push(
      this.route.params
        .pipe(
          switchMap(params => {
            if (params["monoPerimeterId"]) {
              this.showBackButton = true;
              const monoPerimeterId = params["monoPerimeterId"];
              // Initialise the filter so we only see the monoperimeter's investments
              return this.getParentAndChildPerimeter(monoPerimeterId).pipe(
                switchMap(() => this.initialiseInvestmentsFilter())
              );
            } else if (this.monoPerimeterId) {
              return this.getParentAndChildPerimeter(this.monoPerimeterId.toString()).pipe(
                switchMap(() => this.initialiseInvestmentsFilter())
              );
            } else {
              return of(null);
            }
          })
        )
        .subscribe(() => {
          this.subscriptions.push(this.refresh());
          this.filteredInvestments$ = combineLatest([this.investments$, this.searchText$.pipe(startWith(""))]).pipe(
            map(([investments, searchText]) => this.searchByKeyWords({ investments, searchText }))
          );
        })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public ionViewDidEnter() {
    this.refresh();
  }

  /**
   * Fetch investments from database
   * @param refresher
   */
  public refresh(refresher = null): Subscription {
    const yearLess1 = new Date().getFullYear() - 1;
    return combineLatest([
      this.searchService.searchInvestments(),
      this.investmentsService.newInvestmentCreated$.pipe(
        map(invest => invest.localId),
        startWith("")
      ),
    ])
      .pipe(
        // update investments from searchService
        switchMap(([investments, invLocalId]) => {
          if (this.initiative) {
            // If we are in an initiative, we need to filter out the investments
            // that are already linked to any initiative
            return combineLatest([
              this.initiatives$.pipe(
                map(initiatives => {
                  const initiativesInvestsIds = initiatives?.map(ini => ini.investment?.id);
                  return investments.filter(
                    inv => !initiativesInvestsIds.includes(inv.id) && inv.getSchedule()?.[1] >= yearLess1
                  );
                })
              ),
              of(invLocalId),
            ]);
          } else {
            return combineLatest([
              of(
                investments.filter(inv => {
                  return inv.getSchedule()?.[1] >= yearLess1 && this.isAllowedForRisks(inv);
                })
              ),
              of(invLocalId),
            ]);
          }
        }),
        tap(([investments]) => this.investments$.next(investments))
      )
      .subscribe(([_investments, invLocalId]) => {
        // scroll to top if a new investment is created
        if (invLocalId !== this.lastCreatedInvestmentLocalId && this.virtualScroll) {
          this.lastCreatedInvestmentLocalId = invLocalId;
          this.virtualScroll.scrollToIndex(0);
        }
      });
  }

  /**
   * Don't show the investments that are already attached to a risk
   * @param investment
   * @param siteRisks
   * @returns
   */
  private isAllowedForRisks(investment: Investment) {
    return !this.risksList || !this.risksList.find(risk => risk.investment_ids.includes(investment.id));
  }

  public onInvestmentCliked(investment) {
    if (this.selectMode) {
      this.selectInvestment(investment);
    } else {
      this.openInvestment(investment);
    }
  }

  private async selectInvestment(investment: Investment) {
    this.modalCtrl.dismiss({ investment });
  }

  public openInvestment(investment: Investment): void {
    this.subscriptions.push(
      this.scope
        .getCurrentMultiPerimeter()
        .pipe(first())
        .subscribe(perimeter => {
          this.router.navigate(["perimeters", perimeter.id, "investment-detail", investment.id || investment.localId]);
        })
    );
  }

  /**
   * @deprecated
   */
  public loadInvestments(): Observable<void> {
    return of(null).pipe(tap(() => this.refresh()));
  }

  /**
   * Filter investments from label
   * @pure
   * @param arg {investments: Investment[], searchText: string}
   * @returns
   */
  private searchByKeyWords(arg: { investments: Investment[]; searchText: string }) {
    const { investments, searchText } = arg;
    if (!searchText) return investments;
    return investments.filter(investment =>
      cleanFilterString(investment.label).includes(cleanFilterString(searchText))
    );
  }

  public updateSearchKeyword(event: any) {
    const searchText = event.target.value;
    this.searchText$.next(searchText);
  }

  public cancel() {
    if (this.selectMode) {
      this.modalCtrl.dismiss({});
    }
  }

  public async addInvestment() {
    this.subscriptions.push(
      this.scope
        .getCurrentMultiPerimeter()
        .pipe(first())
        .subscribe(multiPerimeter => {
          const perimeter = multiPerimeter.sub_perimeters.find(
            monoPerimeter =>
              monoPerimeter.id === this.initiative?.perimeter || monoPerimeter.id === this.monoPerimeterId
          );
          const navExtras: NavigationExtras = {
            state: {
              multiPerimeter: multiPerimeter,
              monoPerimeter: perimeter,
            },
          };
          this.router.navigate(["investment-add"], navExtras);
          this.modalCtrl.dismiss({ waitForNewInvestment: true });
        })
    );
  }

  public backButtonClicked() {
    if (this.hasPreviousPage) {
      this.navCtrl.pop();
    } else {
      this.navCtrl.navigateBack("perimeter");
    }
  }

  private initialiseInvestmentsFilter() {
    return this.searchService.generateInvestmentsFilters(true).pipe(tap(filters => this.setPerimetersFilter(filters)));
  }

  private setPerimetersFilter(filters) {
    let perimeterFilter = null;
    perimeterFilter = filters.find(filter => filter instanceof InvestmentSearch.PerimeterSearchFilter);
    if (perimeterFilter) {
      let selectedPerimeters = [];
      selectedPerimeters = perimeterFilter.availableValues.filter(val => {
        if (this.parentPerimeter.localId) {
          return val.localId === this.parentPerimeter.localId;
        } else {
          return val.id === this.parentPerimeter.id;
        }
      });
      if (selectedPerimeters.length > 0) {
        perimeterFilter.selectedValues = selectedPerimeters;
        this.searchService.investmentFilterSelected(perimeterFilter);
        this.searchService.updateLvl2PerimeterFilter(perimeterFilter);
        if (this.childPerimeter) {
          this.setChildrenPerimetersFilter(filters);
        }
      }
    }
  }

  private setChildrenPerimetersFilter(filters) {
    let childrenPerimeterFilter = null;
    childrenPerimeterFilter = filters.find(filter => filter instanceof InvestmentSearch.PerimeterLvl2SearchFilter);
    if (childrenPerimeterFilter) {
      let selectedChildrenPerimeters = childrenPerimeterFilter.availableValues.filter(val => {
        if (this.childPerimeter.localId) {
          return val.localId === this.childPerimeter.localId;
        } else {
          return val.id === this.childPerimeter.id;
        }
      });
      if (selectedChildrenPerimeters.length > 0) {
        childrenPerimeterFilter.selectedValues = selectedChildrenPerimeters;
        this.searchService.investmentFilterSelected(childrenPerimeterFilter);
      }
    }
  }

  private getParentAndChildPerimeter(monoPerimeterId: string) {
    return this.scope.getCurrentMultiPerimeter().pipe(
      tap(site => {
        const monoPerimeter = site.sub_perimeters.find(perimeter => {
          if (monoPerimeterId.includes(".")) {
            // we have a localId
            return perimeter.localId === monoPerimeterId;
          } else {
            return perimeter.id === parseInt(monoPerimeterId);
          }
        });
        if (monoPerimeter) {
          if (monoPerimeter.level_parent_local_id) {
            this.parentPerimeter = site.sub_perimeters.find(
              perimeter => perimeter.localId === monoPerimeter.level_parent_local_id
            );
            this.childPerimeter = monoPerimeter;
          } else if (monoPerimeter.level_parent) {
            this.parentPerimeter = site.sub_perimeters.find(perimeter => perimeter.id === monoPerimeter.level_parent);
            this.childPerimeter = monoPerimeter;
          } else {
            this.parentPerimeter = monoPerimeter;
          }
        }
      })
    );
  }

  public async openInvestmentsFilters() {
    const filtersModal = await this.modalCtrl.create({
      component: InvestmentsFiltersComponent,
    });
    await filtersModal.present();
    await filtersModal.onDidDismiss().then(() => this.refresh());
  }
}
