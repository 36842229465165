<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button *ngIf="selectMode" icon-only (click)="cancel()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
      <ion-button *ngIf="showBackButton" (click)="backButtonClicked()">
        <i class="fa-solid fa-circle-chevron-left back-icon"></i>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
    <ion-buttons
      class="top-buttons"
      slot="end"
      *ngIf="{
      hasFiltersSelected: hasFiltersSelected$ | async,
      filteredInvestments: filteredInvestments$ | async
    } as obs">
      <ion-button
        icon-only
        [color]="obs.hasFiltersSelected ? 'danger' : 'default'"
        (click)="openInvestmentsFilters()"
        *ngIf="showFilters">
        <span *ngIf="obs.hasFiltersSelected && obs.filteredInvestments !== null" class="filter-result-counter">
          ({{ obs.filteredInvestments.length }})
        </span>
        <i class="fal fa-filter"></i>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar
      [(ngModel)]="searchText"
      (ionInput)="updateSearchKeyword($event)"
      placeholder="{{ 'Search' | translate }}"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div
    *ngIf="filteredInvestments$ | async as investments; else loadingEl"
    [ngStyle]="{ height: '100%', overflow: 'scroll' }">
    <ion-list lines="full">
      <investment-item
        *ngFor="let investment of investments"
        [investment]="investment"
        (investmentClick)="onInvestmentCliked(investment)"
        [disabled]="!investment.id"></investment-item>

      <div class="space"></div>
    </ion-list>
  </div>

  <ng-template #loadingEl>
    <loader></loader>
  </ng-template>
</ion-content>

<ion-fab vertical="center" horizontal="end" slot="fixed" *ngIf="showAddButton">
  <ion-fab-button ion-fab (click)="addInvestment()" color="investment">
    <i class="fal fa-2x fa-plus"></i>
  </ion-fab-button>
</ion-fab>
