import { formatNumber } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AssetsService } from "@services";
import { AuthService } from "@services/auth.service";
import { InvestmentsService } from "@services/investments.service";
import { AssetType, PriceSheet } from "@structs";
import { combineLatest, Subscription } from "rxjs";
import { I18nService } from "src/app/services/i18n.service";

@Component({
  selector: "app-price-sheet-detail",
  templateUrl: "./price-sheet-detail.page.html",
  styleUrls: ["./price-sheet-detail.page.scss"],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PriceSheetDetailPage implements OnInit, OnDestroy {
  @Input() public priceSheet: PriceSheet = null;
  @Input() public modalMode: boolean = false;
  public assetType: AssetType = null;
  private subscriptions: Subscription[] = [];
  public loading: boolean = true;
  public canEdit: boolean;

  constructor(
    private route: ActivatedRoute,
    private investmentsApi: InvestmentsService,
    private translate: TranslateService,
    private i18nService: I18nService,
    private assetsApi: AssetsService,
    private authApi: AuthService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      combineLatest([
        this.route.params,
        this.investmentsApi.getPriceSheets(),
        this.assetsApi.getCategories(),
        this.authApi.hasCurrentUserPermissions(["edit_price_sheet"]),
      ]).subscribe(([params, priceSheets, categories, canEdit]) => {
        if (params["priceSheetId"]) {
          this.priceSheet = priceSheets.find(sheet => {
            return params["priceSheetId"] && sheet.id === parseInt(params["priceSheetId"]);
          });
        }
        if (this.priceSheet) {
          categories.map(category => {
            category.children.map(subCategory => {
              const assetType = subCategory.children.find(assetType => assetType.id === this.priceSheet.asset_type);
              if (assetType) {
                this.assetType = assetType;
              }
            });
          });
        }
        this.canEdit = canEdit;
        this.loading = false;
      })
    );
  }

  public formatPrice(price: number): string {
    return formatNumber(price, this.translate.getBrowserLang()) + " " + this.i18nService.getCurrency();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
