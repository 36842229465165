import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IonItemSliding, ModalController } from "@ionic/angular";
import { Subscription } from "rxjs";

import { SearchService } from "../../services/search.service";
import { InvestmentSearch } from "../../structs/search";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "investments-filters",
  templateUrl: "./investments-filters.component.html",
  styleUrls: ["./investments-filters.component.scss"],
})
export class InvestmentsFiltersComponent implements OnInit, OnDestroy {
  public defaultValueLabel: string;
  public filters: InvestmentSearch.InvestmentSearchFilter<any>[] = [];
  public enabledFilters: boolean = false;
  public filtersTotal: number = 0;
  public filtersChanged: boolean = false;
  private filtersObj: {
    [key: string]: InvestmentSearch.InvestmentSearchFilter<any>[];
  } = {};

  private subscriptions: Subscription[] = [];

  constructor(
    private searchService: SearchService,
    private translate: TranslateService,
    private modalCtrl: ModalController
  ) {
    this.translate.get("All values").subscribe(defaultValueLabel => (this.defaultValueLabel = defaultValueLabel));
    this.searchService.generateInvestmentsFilters().subscribe(filters => (this.filters = filters));
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.searchService.investmentsFiltersSelected.subscribe(enabledFilters => (this.enabledFilters = enabledFilters))
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public dismiss(): void {
    this.modalCtrl.dismiss();
  }

  public clearFilters(): void {
    this.searchService.clearInvestmentsFilters();
  }

  public clearFilter(item: IonItemSliding, filter: InvestmentSearch.InvestmentSearchFilter<any>): void {
    filter.clearSelected();
    this.searchService.investmentFilterSelected(filter);
    this.searchService.updateInvestTechnicalCategoriesFilter(filter, true);
    this.searchService.updateLvl2PerimeterFilter(filter);
    item.close();
  }

  public onFilterSelected(filter: InvestmentSearch.InvestmentSearchFilter<any>): void {
    const filterType = filter.label;
    let filterArray = this.filtersObj[filterType];

    if (!filterArray) {
      filterArray = [];
      this.filtersObj[filterType] = filterArray;
    }
    filterArray.push(filter);
    this.filtersTotal = Object.keys(this.filtersObj).length;
    // If a parent category has been selected, update the available values for the child category
    this.searchService.updateInvestTechnicalCategoriesFilter(filter);
    this.searchService.updateLvl2PerimeterFilter(filter);
    this.filtersChanged = true;
  }

  public applyFilters(): void {
    for (let filterType in this.filtersObj) {
      const filterArray = this.filtersObj[filterType];
      filterArray.forEach(filter => this.searchService.investmentFilterSelected(filter));
    }
    this.dismiss();
  }

  public showOnlyParentButton(filter: InvestmentSearch.InvestmentSearchFilter<any>) {
    return filter instanceof InvestmentSearch.PerimeterSearchFilter;
  }

  public onlyParentPerimeterToggle(filter: InvestmentSearch.InvestmentSearchFilter<any>) {
    filter.onlyParent = !filter.onlyParent;
  }
}
